/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// GENERAL
// ---------------

$color1:              #ff2556;
$color2:              #f05986;
$color3:              #129d9d;
$color4:              #3a3a3b;
$color5:              #0387d9;

// TYPOGRAPHY
// ---------------

$textColor:           #333;

$baseFontSize:        18px;
$rem-base:            $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      "Lato", Helvetica, Arial, sans-serif;
$secondaryFontFamily: $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       $color3;

$hrColor:             #DDD;
